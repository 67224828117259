import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import Hls from 'hls.js'

const Hero = ({ src, poster }) => {
  const videoRef = useRef(null) // Assuming you need this for the new useEffect

  // New useEffect for HLS video handling
  useEffect(() => {
    let hls
    if (videoRef.current) {
      const video = videoRef.current
      if (Hls.isSupported()) {
        hls = new Hls()
        hls.loadSource(src)
        hls.attachMedia(video)
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src
      }
    }
    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [src])

  return (
    <Wrap>
      <video
        ref={videoRef}
        autoPlay={true}
        width="100%"
        height="100%"
        muted={true}
        playsInline={true}
        loop={true}
        poster={poster}
        controls={false}
      />
    </Wrap>
  )
}

const Wrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  video {
    height: 100%;
    width: 100%;
    top: 0 !important;
    left: 0 !important;
  }
`

export default Hero
